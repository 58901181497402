import React from 'react';
import './AudiencePreview.scss';

import IconValueComparison from '../../../../common/IconValueComparison/IconValueComparison';
import { BarChart } from '../../../../common/BarChart/BarChart';
import { formatNumberToUnits } from '../../../../../utils/NumberUtils';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import SubmitButton from '../../../../common/SubmitButton/SubmitButton';
import Icon from '../../../../common/Icon/Icon';
import Separator from '../../../../common/Separator/Separator';

const AudiencePreview = ({
  hasData,
  populationDist,
  householdPopulationDist,
  genderDist,
  ageDist,
  isError,
  isTooNarrow,
  isTooWide,
  isEntitiesLimit,
  onExploreAudience,
  elementAfterSeparator,
  isContextualUniquenessError,
  hideAudiencePreview,
  showAudienceIpCount,
}) => (
  <div className="audience-preview-component">
    <div className="summary">
      <div className="inline-container">
        {!isContextualUniquenessError && hasData && !hideAudiencePreview && (
          <>
            <span className={classNames('audience-summary-title', 'highlighted')}>Audience Preview</span>
            <span className={classNames('population-value', 'highlighted')}>
              {formatNumberToUnits(populationDist, 1)}
            </span>
            <span className="population-users">Users</span>
            <span className="audience-separator" />
            <span className="gender-comparison">
              {genderDist && (
                <IconValueComparison
                  firstIconId="male-detailed"
                  firstDisplayValue={genderDist.male.displayValue}
                  firstValue={genderDist.male.value}
                  secondIconId="female-detailed"
                  secondDisplayValue={genderDist.female.displayValue}
                  secondValue={genderDist.female.value}
                />
              )}
            </span>
            <span className="audience-separator" />
            <div className="age-chart">
              <BarChart data={ageDist} theme="preview" />
            </div>
          </>
        )}
        {showAudienceIpCount && (
          <React.Fragment>
            <span className={classNames('households-value', 'highlighted')}>
              {formatNumberToUnits(householdPopulationDist, 1)}
            </span>
            <span className="households-users">Households</span>
          </React.Fragment>
        )}
      </div>
      {!hideAudiencePreview &&
        (isError ? (
          <span className="audience-summary-title">Audience Preview:</span>
        ) : (
          <AudiencePreviewErrorMessage {...{ isTooNarrow, isTooWide, isEntitiesLimit, isContextualUniquenessError }} />
        ))}
    </div>

    <div className="buttons-container">
      {!hideAudiencePreview && (
        <SubmitButton label="Explore Audience" isDisabled={!hasData} onClick={onExploreAudience} />
      )}
      {elementAfterSeparator && (
        <React.Fragment>
          {!hideAudiencePreview && <Separator />}
          {elementAfterSeparator}
        </React.Fragment>
      )}
    </div>
  </div>
);

const AudiencePreviewErrorMessage = ({ isTooNarrow, isTooWide, isEntitiesLimit, isContextualUniquenessError }) => {
  if (!isTooNarrow && !isTooWide && !isEntitiesLimit && !isContextualUniquenessError) return null;

  const tooNarrowMessage =
    isTooNarrow && 'The audience you have selected is too narrow, please expand your audience criteria';
  const tooWideMessage = isTooWide && 'The audience you have selected is too broad, please refine your criteria';
  const entitiesLimitMessage = isEntitiesLimit && 'Too many entities selected, please refine your criteria';
  const contextualNotUniqueMessage =
    isContextualUniquenessError &&
    'The audience you have selected is not unique enough. This will result in a Target that is not well skewed towards the audience. Please refine your audience';

  return (
    <div className="audience-preview-error-message">
      <Icon iconId="exclamation-in-circle" className="exclamation-icon" />
      {tooNarrowMessage || tooWideMessage || entitiesLimitMessage || contextualNotUniqueMessage}
    </div>
  );
};

AudiencePreview.propTypes = {
  hasData: PropTypes.bool,
  populationDist: PropTypes.number,
  householdPopulationDist: PropTypes.number,
  genderDist: PropTypes.shape({
    male: PropTypes.shape({
      value: PropTypes.number,
      displayValue: PropTypes.string,
    }),
    female: PropTypes.shape({
      value: PropTypes.number,
      displayValue: PropTypes.string,
    }),
  }),
  ageDist: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
      displayValue: PropTypes.string,
    })
  ),
  isError: PropTypes.bool,
  isTooNarrow: PropTypes.bool,
  isTooWide: PropTypes.bool,
  isEntitiesLimit: PropTypes.bool,
  onExploreAudience: PropTypes.func,
  elementAfterSeparator: PropTypes.element,
  isContextualUniquenessError: PropTypes.bool,
  hideAudiencePreview: PropTypes.bool,
  showAudienceIpCount: PropTypes.bool,
};

AudiencePreviewErrorMessage.propTypes = {
  isTooNarrow: PropTypes.bool,
  isTooWide: PropTypes.bool,
  isEntitiesLimit: PropTypes.bool,
  isContextualUniquenessError: PropTypes.bool,
};

export default AudiencePreview;
